import React from "react";

import { Title } from "src/components/page";
import { Tiles, Tile } from "src/components/tiles";
import { loremIpsum } from "src/lib/placeholder";

const GenerativeArt = () => (
  <>
    <Title>Generative art</Title>

    <Tiles>
      {[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0].map((_, i) => (
        <Tile key={i} />
      ))}
    </Tiles>
  </>
);

export default GenerativeArt;
