import React from "react";

import "./tile.scss";

const Tile = ({ children }) => (
  <div className="TileContainer">
    <div className="TileWrapper">
      <div className="Tile">{children}</div>
    </div>
  </div>
);

export default Tile;
